import React, { useEffect, useState } from "react";
import EventNotification from "../../../interfaces/eventNotification/EventNotification";
import { useNavigate } from "react-router-dom";
import { getTimeAgo } from "../../../utilities/dateUtils";
import Avatar from "../../../interfaces/avatar/Avatar";
import { getAvatarsFromStorage } from "../../../services/fileServices/FileService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBolt, faUser, faList } from "@fortawesome/free-solid-svg-icons";

interface NotificationBlockProps {
  notification: EventNotification;
}

type ReplacementType = {
  [key: string]: string;
};

const NotificationBlock: React.FC<NotificationBlockProps> = ({
  notification,
}) => {
  const navigate = useNavigate();
  const [avatars, setAvatars] = useState<Avatar[] | null>([]);

  const renderMessageWithLinks = (message: string) => {
    const replacements: ReplacementType = {
      "{task}": "task",
      "{author}": "user",
      "{file}": "file",
      "{activity}": "task_action",
    };

    const parts = message.split(/(\{[a-zA-Z]+\})/g); // Split the message by placeholders
    return parts.map((part, index) => {
      if (replacements[part]) {
        const type = replacements[part];
        const resource = notification.notificationResources?.find(
          (res: any) => res.resourceType === type
        );
        if (type === "task_action") {
          return (
            <div
              key={index}
              className="inline-flex align-middle items-center bg-[rgba(108,117,125,0.2)] px-2 py-0.5 cursor-pointer rounded truncate shadow-none border-2 border-[rgba(108,117,125,0.2)] hover:shadow-lg"
              onClick={() => navigate(resource?.resourceId || "#")}
            >
              <FontAwesomeIcon icon={faBolt} className="text-[#6C757D] mr-1" />
              <span>{resource?.resourceName || "Activity"}</span>
            </div>
          );
        }
        if (type === "user") {
          return (
            <div
              key={index}
              className="inline-flex align-middle items-center bg-[rgba(108,117,125,0.2)] px-2 py-0.5 cursor-pointer rounded truncate shadow-none border-2 border-[rgba(108,117,125,0.2)] hover:shadow-lg"
              onClick={() => navigate(resource?.resourceId || "#")}
            >
              <FontAwesomeIcon icon={faUser} className="text-[#6C757D] mr-1" />
              <span>{resource?.resourceName || "User"}</span>
            </div>
          );
        }
        // if (type === "task") {
        //   return (
        //     <div
        //       key={index}
        //       className="inline-flex align-middle items-center bg-[rgba(108,117,125,0.2)] px-2 py-0.5 cursor-pointer rounded truncate font-normal shadow-none border-2 border-[rgba(108,117,125,0.2)] hover:shadow-lg"
        //       onClick={() => navigate(resource?.resourceId || "#")}
        //     >
        //       <FontAwesomeIcon icon={faList} className="text-[#6C757D] mr-1" />
        //       <span>
        //         {resource?.resourceName || "Task"}
        //       </span>
        //     </div>
        //   );
        // }
        if (resource) {
          return (
            <div
              key={index}
              className="inline"
              // href={resource.resourceId}
            >
              {resource.resourceName}
            </div>
          );
        }
      }
      return <span key={index}>{part}</span>; // If no replacement, return the part as is
    });
  };

  useEffect(() => {
    const storedAvatars = getAvatarsFromStorage();
    setAvatars(storedAvatars);
  }, []);

  const findAvatarById = (userId: string | undefined): string | null => {
    if (userId) {
      const avatarObj = avatars?.find((avatar) => avatar.id === userId);
      return avatarObj ? `data:image/png;base64,${avatarObj.avatar}` : null;
    } else {
      return null;
    }
  };

  const renderTitleWithLinks = (message: string) => {
    const replacements: ReplacementType = {
      "{task}": "task",
      "{author}": "user",
      "{file}": "file",
    };

    const parts = message.split(/(\{[a-zA-Z]+\})/g); // Split the message by placeholders
    return parts.map((part, index) => {
      if (replacements[part]) {
        const type = replacements[part];
        const resource = notification.notificationResources?.find(
          (res: any) => res.resourceType === type
        );
        return (
          <div
            key={index}
            className="inline cursor-pointer"
            onClick={() => navigate(resource?.resourceId || "#")}
          >
            {resource?.resourceName || "Title"}
          </div>
        );
      }
      return <span key={index}>{part}</span>; // If no replacement, return the part as is
    });
  };

  return (
      <div
        className="flex justify-start align-middle h-[85px] gap-2 max-h-[85px] rounded-3xl m-2 border hover:bg-muted-dark hover:dark:bg-muted hover:cursor-pointer py-2 overflow-hidden"
        onClick={() => navigate(`/profile/inbox?message=${notification.id}`)}
      >
        {notification.read ? (
          <div className="flex justify-center align-middle min-h-2 min-w-2 rounded-full my-auto -me-1 ms-1"></div>
        ) : (
          <div className="flex justify-center align-middle min-h-2 min-w-2 bg-[#3DD598] rounded-full my-auto -me-1 ms-1"></div>
        )}
        <div className="flex align-middle justify-center">
          <div className="rounded-full w-7 h-7 bg-background dark:bg-background-dark my-auto">
            <div className="relative">
              {findAvatarById(notification.createdByUser?.id) ? (
                <img
                  src={findAvatarById(notification.createdByUser?.id)!}
                  alt="profile_picture"
                  className="h-7 w-7 rounded-full me-2"
                />
              ) : (
                <div className="w-7 h-7 font-bold flex items-center justify-center rounded-full bg-gray-300 dark:text-text">
                  <div className="2xl:text-sm ">
                    {notification.createdByUser?.firstName[0] +
                      "" +
                      notification.createdByUser?.lastName[0]}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="2xl:text-[15px] text-[11.5px] flex flex-col w-full relative">
          <div className="text-start font-bold">
            {renderTitleWithLinks(notification.title)}
          </div>
          <div className="2xl:text-[12px] text-[10px] col-span-2  text-ellipsis text-start ">
            {renderMessageWithLinks(notification.message)}
          </div>
          <div className="text-muted 2xl:text-xs text-[10px] text-end absolute -bottom-1.5 right-2">
            {getTimeAgo(notification.createdAt)}
          </div>
        </div>
      </div>
  );
};

export default NotificationBlock;
