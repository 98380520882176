import React, { useEffect, useState, useCallback } from "react";
import { useAuth } from "../../../firebase/AuthProvider";
import Avatar from "../../../interfaces/avatar/Avatar";
import { TeamUser } from "../../../interfaces/team/TeamUser";
import { getTeamBySupervisor } from "../../../services/userServices/TeamService";
import './Tasks.css'

type TaskUserListProps = {
  avatars: Avatar[] | undefined | null;
  toggleUserFilter: (id: string[]) => void;
};

const TaskUserList: React.FC<TaskUserListProps> = ({
  avatars,
  toggleUserFilter,
}) => {
  const { currentUser, currentRoles } = useAuth();
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [users, setUsers] = useState<TeamUser[] | undefined>();

  useEffect(() => {
    const getTeam = async () => {
      if (currentRoles?.some((role) => role.name === 'SUPERVISOR') && currentUser) {
        const authToken = await currentUser.getIdToken();
        const team = await getTeamBySupervisor(authToken);
        if (team) {
          let currentUserItem:TeamUser = { 
            id: currentUser.uid, 
            firstName: currentUser.displayName || "M", 
            lastName: currentUser.displayName || "E"
          }

          let teamWithCurrentUser = [currentUserItem, ...team]
          setUsers(teamWithCurrentUser);
        }
      }
    };

    if (users === undefined) {
      getTeam();
    }
  }, [currentUser, currentRoles]);

  const updateSelectedUsers = (id:string) => { 
    if (selectedUsers.includes(id)) { 
      const newList = selectedUsers.filter(user => user !== id)
      setSelectedUsers(newList);
      toggleUserFilter(newList)
      console.log(newList);

    } else { 
      const newList = [...selectedUsers, id]
      setSelectedUsers(newList)
      toggleUserFilter(newList)
      console.log(newList);
      
    }
  }


  const findAvatarById = (userId: string): string | null => {
    const avatarObj = avatars?.find((avatar) => avatar.id === userId);
    return avatarObj ? `data:image/png;base64,${avatarObj.avatar}` : null;
  };

  return (
    <div className="bg-gray-200 p-2 rounded-tr-lg flex flex-col gap-1">
      {currentRoles?.some((role) => role.name === 'SUPERVISOR') && users && users?.map((user, index) => {
        const avatarUrl = findAvatarById(user.id);
        return (
          <div
            className={`${selectedUsers.some(currentUser => currentUser === user.id) ? 'selected-user' : 'non-selected-user'} border-[3px] cursor-pointer w-8 h-8 font-bold flex items-center justify-center rounded-full dark:text-text`}
            key={index}
            onClick={() => updateSelectedUsers(user.id)}
          >
            {avatarUrl ? (
              <img
                src={avatarUrl}
                alt={`${user.firstName} ${user.lastName}`}
                className="w-full h-full rounded-full object-cover"
              />
            ) : (
              <div className="text-sm content no-select">
                {user.firstName[0] + user.lastName[0]}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default TaskUserList;
