import {
  faAngleDown,
  faAngleRight,
  faRobot,
  faWandMagicSparkles,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { getSuggestionsForUser, markSuggestionAsViewed } from "../../../services/suggestionServices/SuggestionService";
import { useAuth } from "../../../firebase/AuthProvider";
import Suggestion from "../../../interfaces/suggestion/Suggestion";

const GeneratedSuggestionComponent: React.FC = () => {
  const { currentUser } = useAuth();
  const [toggled, setToggled] = useState<boolean>(false);
  const [suggestions, setSuggestions] = useState<Suggestion[]>();
  const [reload, setReload] = useState<boolean>(false);

  useEffect(() => {
    const getSuggestions = async () => {
      if (currentUser) {
        const authToken = await currentUser?.getIdToken();
        const response = await getSuggestionsForUser(
          authToken,
          currentUser.uid
        );
        if (response !== null) {
          setSuggestions(response);
        }
      }
    };

    getSuggestions();
  }, [reload]);

  const viewSuggestion = async (suggestionId: number) => {
    if (currentUser) { 
      const authToken = await currentUser.getIdToken();
      markSuggestionAsViewed(authToken, suggestionId);
      setReload(!reload);
    }
  };

  return (
    <div className="">
      {suggestions && suggestions.length > 0 && (
        <div className="shadow-xl rounded-lg w-[300px] border">
          <div
            className="flex justify-between rounded-t-lg rounded-b-lg border-b bg-white p-2 cursor-pointer"
            onClick={() => setToggled(!toggled)}
          >
            <div className="flex align-middle items-center gap-2">
              <FontAwesomeIcon
                className="text-[#3dd598] shake"
                icon={faWandMagicSparkles}
              />
              <div>Suggestions</div>
            </div>
            <div>
              <FontAwesomeIcon icon={toggled ? faAngleRight : faAngleDown} />
            </div>
          </div>

          {toggled &&
            suggestions &&
            suggestions.map((suggestion, index) => (
              <div key={index} className="h-[200px] bg-gray-100 p-2 flex gap-2">
                <div className="bg-white shadow-lg flex justify-center align-middle items-center w-8 h-8 rounded-full">
                  <FontAwesomeIcon icon={faRobot} className="text-gray-400" />
                </div>
                <div className="bg-white rounded-lg border shadow-lg p-2 text-sm text-muted w-full h-auto flex">
                  <div>
                    {suggestion.message}
                    <a
                      href={suggestion.followUpUrl}
                      className="text-blue-500 text-sm cursor-pointer w-full h-auto ps-1"
                    >
                      View
                    </a>{" "}
                    /
                    <span className="text-blue-500 text-sm cursor-pointer w-full h-auto ps-1" onClick={() => viewSuggestion(suggestion.id)}>
                      Dismiss
                    </span>
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default GeneratedSuggestionComponent;
