import React, { useEffect, useState } from "react";
import { useAuth } from "../../../firebase/AuthProvider";
import { getSuggestionRules } from "../../../services/suggestionServices/SuggestionService";
import SuggestionRule from "../../../interfaces/suggestion/SuggestionRule";
import {
  formatDateToMMDDYYYY,
  formatDateToWords,
} from "../../../utilities/dateUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const SuggestionRulesComponent: React.FC = () => {
  const { currentUser } = useAuth();
  const [toggled, setToggled] = useState<boolean>(false);
  const [suggestionRules, setSuggestionRules] = useState<SuggestionRule[]>();

  useEffect(() => {
    const getSuggestions = async () => {
      if (currentUser) {
        const authToken = await currentUser?.getIdToken();
        const response = await getSuggestionRules(authToken);
        if (response) {
          setSuggestionRules(response);
        }
      }
    };

    getSuggestions();
  }, []);

  return (
    <div className="bg-background -z-50">
      <div className="h-[300px] w-full bg-navBackground dark:bg-navBackground-dark -z-10 rounded-b-3xl"></div>

      <div className="bg-white w-11/12 mx-auto rounded-3xl p-5 relative -top-64 -mt-10 max-w-[1800px] min-h-[500px] border">
        <div className="flex justify-between">
          <div className="inter-font text-3xl">Suggestion Rules</div>
          <button className="flex gap-2 justify-center items-center align-middle bg-[#3DD598] text-white p-2 rounded shadow hover:shadow-none">
            <FontAwesomeIcon icon={faPlus} />
            <div>New Rule</div>
          </button>
        </div>
        <div className="mt-5">
          {suggestionRules &&
            suggestionRules.map((rule, index) => (
              <CollapsibleRule key={index} rule={rule} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default SuggestionRulesComponent;

const CollapsibleRule: React.FC<{ rule: SuggestionRule }> = ({ rule }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-200 mb-4">
      <button
        className="w-full flex justify-between items-center p-4 bg-gray-100 hover:bg-gray-200 rounded-lg"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="font-semibold text-lg">{rule.name}</span>
        <svg
          className={`w-5 h-5 transform transition-transform ${
            isOpen ? "rotate-180" : "rotate-0"
          }`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>
      {isOpen && (
        <div className="flex gap-10 p-4 text-gray-900 bg-gray-50 inter-font">
          <div className="flex-col text-nowrap gap-2">
            <p className="font-bold">Rule Name: </p>
            <p className="font-bold">Rule Type: </p>
            <p className="font-bold">Condition Expression: </p>
            <p className="font-bold">Suggestion Text: </p>
            <p className="font-bold">Follow-up URL: </p>
            <p className="font-bold">Recipient Role(s): </p>
            <p className="font-bold">Created At: </p>
            <p className="font-bold">Last Updated: </p>
            <p className="font-bold">Is Active: </p>
          </div>
          <div className="flex-col text-nowrap gap-2 w-full">
            <p>{rule.name}</p>
            <p>{rule.eventType}</p>
            <p>{rule.conditionExpression}</p>
            <p>{rule.suggestionText}</p>
            <p>{rule.followUpUrlTemplate}</p>
            <p>{rule.recipientRole}</p>
            <p>
              {rule.createdAt
                ? formatDateToWords(new Date(rule.createdAt))
                : rule.createdAt}
            </p>
            <p>
              {rule.updatedAt
                ? formatDateToWords(new Date(rule.updatedAt))
                : rule.createdAt
                ? formatDateToWords(new Date(rule.createdAt))
                : rule.createdAt}
            </p>
            <input
              type="checkbox"
              disabled={true}
              checked={rule.isActive}
              className="checked:bg-[#3DD598] bg-gray-400 border-none rounded "
            />
          </div>
          <a href={`/edit/suggestionRules/${rule.id}`} className="text-[#3DD598]">edit</a>
        </div>
      )}
    </div>
  );
};
