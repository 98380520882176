// SuggestionService.ts

import Suggestion from "../../interfaces/suggestion/Suggestion";
import SuggestionRule from "../../interfaces/suggestion/SuggestionRule";

// Function to fetch suggestions for a user
export async function getSuggestionsForUser(
  authToken: string,
  userId: string
): Promise<Suggestion[] | null> {
  try {
    const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/suggestions`;

    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      // Handle non-OK responses
      return null;
    }

    const res = await response.json();

    // Map the suggestion data
    const suggestions: Suggestion[] = res.map((suggestion: any) => ({
      id: suggestion.id,
      taskId: suggestion.taskId,
      userId: suggestion.userId,
      ruleId: suggestion.ruleId,
      message: suggestion.message,
      followUpUrl: suggestion.followUpUrl,
      isViewed: suggestion.isViewed,
      createdAt: suggestion.createdAt,
    }));

    return suggestions;
  } catch (error) {
    console.error("Error fetching suggestions:", error);
    return null;
  }
}

// Function to mark a suggestion as viewed
export async function markSuggestionAsViewed(
  authToken: string,
  suggestionId: number
): Promise<boolean> {
  try {
    const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/suggestions/${suggestionId}/markViewed`;

    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    return response.ok;
  } catch (error) {
    console.error("Error marking suggestion as viewed:", error);
    return false;
  }
}

// Function to create a new suggestion rule
export async function createSuggestionRule(
  authToken: string,
  newRule: SuggestionRule
): Promise<SuggestionRule | null> {
  try {
    const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/suggestionRules`;

    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newRule),
    });

    if (!response.ok) {
      // Handle non-OK responses
      return null;
    }

    const res = await response.json();

    // Map the returned data to SuggestionRule interface
    const createdRule: SuggestionRule = {
      id: res.id,
      name: res.name,
      eventType: res.eventType,
      conditionExpression: res.conditionExpression,
      suggestionText: res.suggestionText,
      followUpUrlTemplate: res.followUpUrlTemplate,
      recipientRole: res.recipientRole,
      isActive: res.isActive,
      createdAt: res.createdAt,
      updatedAt: res.updatedAt,
    };

    return createdRule;
  } catch (error) {
    console.error("Error creating suggestion rule:", error);
    return null;
  }
}

// Function to create a new suggestion rule
export async function getSuggestionRules(
    authToken: string,
  ): Promise<SuggestionRule[] | null> {
    try {
      const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/suggestions/rules`;
  
      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      });
  
      if (!response.ok) {
        // Handle non-OK responses
        return null;
      }
  
      const res = await response.json();
  
      // Map the returned data to SuggestionRule interface
      const suggestionRules: SuggestionRule[] = res.map((suggestionRule: SuggestionRule) => ({
        id: suggestionRule.id,
        name: suggestionRule.name,
        eventType: suggestionRule.eventType,
        conditionExpression: suggestionRule.conditionExpression,
        suggestionText: suggestionRule.suggestionText,
        followUpUrlTemplate: suggestionRule.followUpUrlTemplate,
        recipientRole: suggestionRule.recipientRole,
        isActive: suggestionRule.isActive,
        createdAt: suggestionRule.createdAt,
        updatedAt: suggestionRule.updatedAt,
      }));
  
      return suggestionRules;
    } catch (error) {
      console.error("Error creating suggestion rule:", error);
      return null;
    }
  }