import {
  faCaretDown,
  faCaretRight,
  faPencil,
  faPlay,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../firebase/AuthProvider";
import SuggestionRulesComponent from "../generatedSuggestions/SuggestionRulesComponent";

const Settings: React.FC = () => {
  const { currentUser, currentRoles } = useAuth();
  const [soundNotificationToggle, setSoundNotificationToggle] =
    useState<boolean>(false);
  const [selectedSound, setSelectedSound] = useState(
    localStorage.getItem("notificationSound") || "notification-4.mp3"
  );

  const navigate = useNavigate();

  const navigateToEditRecurringTasks = () => {
    navigate("/edit/task/recurring");
  };

  const navigateToEditWorkflows = () => {
    navigate("/edit/workflows");
  };

  const navigateToEditParticipants = () => {
    navigate("/edit/participants");
  };

  const navigateToEditTemplateInterimTasks = () => {
    navigate("/edit/interims");
  };

  const navigateToTemplateFiles = () => {
    navigate("/edit/templateFiles");
  };

  const navigateToActivityTemplate = () => {
    navigate("/task/edit/activity/template");
  };

  const navigateToSuggestionRulesComponent = () => { 
    navigate('/edit/suggestionRules');
  };

  const toggleNotificationSound = () => {
    setSoundNotificationToggle(!soundNotificationToggle);
  };

  const setNotificationSound = (soundFileName: string) => {
    localStorage.setItem("notificationSound", soundFileName);
    setSelectedSound(soundFileName);
  };

  const playSound = (soundFileName: string) => {
    const audio = new Audio(`/notificationTones/${soundFileName}`);
    audio
      .play()
      .then(() => {})
      .catch((error) => {
        console.error(`Error playing ${soundFileName}:`, error);
      });
  };

  return (
    <div className="p-5">
      <h1 className="inter-font text-text text-2xl">Settings</h1>
      {currentRoles?.some((role) => role.name === "PROGRAM MANAGER") ||
      currentRoles?.some((role) => role.name === "PROGRAM MANAGER") ? (
        <div className="mx-4 mt-5">
          <div className="flex justify-between w-full mx-auto rounded-md p-2 hover:bg-text-dark dark:hover:bg-background-dark dark:text-text-dark">
            <div className="dots overflow-hidden">Recurring Tasks</div>
            <div
              onClick={navigateToEditRecurringTasks}
              className="relative flex items-center text-primary cursor-pointer"
            >
              <FontAwesomeIcon icon={faPencil} />
            </div>
          </div>
          {/* <div className="flex justify-between w-full mx-auto rounded-md p-2 hover:bg-text-dark dark:hover:bg-background-dark dark:text-text-dark">
          <div>Workflows</div>
          <div onClick={navigateToEditWorkflows} className="relative flex items-center text-primary cursor-pointer">
            <FontAwesomeIcon icon={faPencil} />
          </div>
        </div> */}
          <div className="flex justify-between w-full mx-auto rounded-md p-2 hover:bg-text-dark dark:hover:bg-background-dark dark:text-text-dark">
            <div>Edit Participants</div>
            <div
              onClick={navigateToEditParticipants}
              className="relative flex items-center text-primary cursor-pointer"
            >
              <FontAwesomeIcon icon={faPencil} />
            </div>
          </div>
          <div className="flex justify-between w-full mx-auto rounded-md p-2 hover:bg-text-dark dark:hover:bg-background-dark dark:text-text-dark">
            <div>Template Interim Tasks</div>
            <div
              onClick={navigateToEditTemplateInterimTasks}
              className="relative flex items-center text-primary cursor-pointer"
            >
              <FontAwesomeIcon icon={faPencil} />
            </div>
          </div>
          <div className="flex justify-between w-full mx-auto rounded-md p-2 hover:bg-text-dark dark:hover:bg-background-dark dark:text-text-dark">
            <div>Template Files</div>
            <div
              onClick={navigateToTemplateFiles}
              className="relative flex items-center text-primary cursor-pointer"
            >
              <FontAwesomeIcon icon={faPencil} />
            </div>
          </div>
          <div className="flex justify-between w-full mx-auto rounded-md p-2 hover:bg-text-dark dark:hover:bg-background-dark dark:text-text-dark">
            <div>Task Activity Types</div>
            <div
              onClick={navigateToActivityTemplate}
              className="relative flex items-center text-primary cursor-pointer"
            >
              <FontAwesomeIcon icon={faPencil} />
            </div>
          </div>
          <div className="flex justify-between w-full mx-auto rounded-md p-2 hover:bg-text-dark dark:hover:bg-background-dark dark:text-text-dark">
            <div>Suggestion Rules</div>
            <div
              onClick={navigateToSuggestionRulesComponent}
              className="relative flex items-center text-primary cursor-pointer"
            >
              <FontAwesomeIcon icon={faPencil} />
            </div>
          </div>
          <div className="relative mt-5">
            <button
              className="hover:bg-gray-200 rounded-3xl p-1 px-2 cursor-pointer relative -top-3 flex items-center align-middle gap-2"
              onClick={toggleNotificationSound}
            >
              Notification Sounds
              {soundNotificationToggle ? (
                <FontAwesomeIcon icon={faCaretDown} />
              ) : (
                <FontAwesomeIcon icon={faCaretRight} />
              )}
            </button>
            {soundNotificationToggle && (
              <div className="flex flex-col gap-4 ms-10 border-l-4 border-blue-500 ps-4">
                {[
                  "notification-1.mp3",
                  "notification-2.mp3",
                  "notification-3.mp3",
                  "notification-4.mp3",
                ].map((sound, index) => (
                  <div
                    key={index}
                    className="flex align-middle items-center gap-2"
                  >
                    <input
                      type="radio"
                      id={sound}
                      name="notificationSound"
                      value={sound}
                      checked={selectedSound === sound}
                      onChange={() => setNotificationSound(sound)}
                    />
                    <label htmlFor={sound}>{sound}</label>
                    <div
                      className="p-2 bg-blue-500 h-6 w-6 flex justify-center align-middle items-center rounded-full text-white cursor-pointer"
                      onClick={() => playSound(sound)}
                    >
                      <FontAwesomeIcon icon={faPlay} className="text-xs" />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="mt-5 flex">
          <div className="relative">
            <button
              className="hover:bg-gray-200 rounded-3xl p-1 px-2 cursor-pointer relative -top-3 flex items-center align-middle gap-2"
              onClick={toggleNotificationSound}
            >
              Notification Sounds
              {soundNotificationToggle ? (
                <FontAwesomeIcon icon={faCaretDown} />
              ) : (
                <FontAwesomeIcon icon={faCaretRight} />
              )}
            </button>
            {soundNotificationToggle && (
              <div className="flex flex-col gap-4 ms-10 border-l-4 border-blue-500 ps-4">
                {[
                  "notification-1.mp3",
                  "notification-2.mp3",
                  "notification-3.mp3",
                  "notification-4.mp3",
                ].map((sound, index) => (
                  <div
                    key={index}
                    className="flex align-middle items-center gap-2"
                  >
                    <input
                      type="radio"
                      id={sound}
                      name="notificationSound"
                      value={sound}
                      checked={selectedSound === sound}
                      onChange={() => setNotificationSound(sound)}
                    />
                    <label htmlFor={sound}>{sound}</label>
                    <div
                      className="p-2 bg-blue-500 h-6 w-6 flex justify-center align-middle items-center rounded-full text-white cursor-pointer"
                      onClick={() => playSound(sound)}
                    >
                      <FontAwesomeIcon icon={faPlay} className="text-xs" />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Settings;
