// TaskService.ts

import { PagedResult } from "../../interfaces/PagedResult";
import InterimTemplateRequest from "../../interfaces/task/InterimTemplateRequest";
import InterimTemplateResponse from "../../interfaces/task/InterimTemplateResponse";
import NewInterimTemplate from "../../interfaces/task/NewInterimTemplate";
import NewTask from "../../interfaces/task/NewTask";
import Task from "../../interfaces/task/Task"; // Import your Task interface
import TaskList from "../../interfaces/task/TaskList";
import TaskRequest from "../../interfaces/task/TaskRequest";

// Function to fetch a task by its ID
export async function getTaskById(
  taskId: string,
  authToken: string
): Promise<Task | null> {
  try {
    const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/tasks/${taskId}`;

    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      // Handle non-OK responses, e.g., 404 for not found
      return null;
    }

    const taskData = await response.json();

    // Map the task data to your Task interface
    const task: Task = {
      id: taskData.id,
      title: taskData.title,
      assignees: taskData.assignees,
      participant: taskData.participant,
      description: taskData.description,
      isReady: taskData.isTaskReady,
      information: taskData.information ? taskData.information : undefined,
      orgId: taskData.organization,
      taskType: {
        id: taskData.type.id,
        type: taskData.type.name,
      },
      taskOrganization: {
        id: taskData.taskOrg.id,
        type: taskData.taskOrg.name,
      },
      subType: {
        id: taskData.subType.id,
        type: taskData.subType.name,
      },
      startDate: new Date(taskData.startDate),
      endDate: new Date(taskData.endDate),
      completionTime: taskData.completionTime
        ? new Date(taskData.completionTime)
        : undefined,
      progress: taskData.progress,
      status: taskData.taskStatus,
      priority: taskData.priority,
      // Add other necessary properties
    };

    return task;
  } catch (error) {
    // Handle network errors or other exceptions
    console.error("Error fetching task:", error);
    return null;
  }
}

// Function to fetch all tasks for logged-in User
export async function getTasks(
  authToken: string,
  userId?: string
): Promise<TaskList[] | null> {
  try {
    let apiUrl = `${process.env.REACT_APP_API_BASE_URL}/tasks`;

    if (userId) {
      apiUrl = `${process.env.REACT_APP_API_BASE_URL}/tasks/user/${userId}`;
    }

    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      // Handle non-OK responses, e.g., 404 for not found
      return null;
    }

    const res = await response.json();

    // Map the task data to your Task interface
    const tasks: TaskList[] = res.map((taskData: any) => ({
      id: taskData.id,
      title: taskData.title,
      assignees: taskData.assignees,
      description: taskData.description,
      orgId: taskData.organization,
      participant: taskData.participant,
      taskType: taskData.type,
      hasAttachments: taskData.hasAttachments,
      taskOrganization: taskData.taskOrg,
      subType: {
        id: taskData.subType.id,
        type: taskData.subType.name,
      },
      startDate: new Date(taskData.startDate),
      endDate: new Date(taskData.endDate),
      completionTime: taskData.completionTime
        ? new Date(taskData.completionTime)
        : undefined,
      progress: 100,
      status: taskData.taskStatus,
      priority: "LOW",
      assigneeRoleName: taskData.assigneeRoleName,
      fromTemplate: taskData.fromTemplate,
      // Add other necessary properties
    }));

    return tasks;
  } catch (error) {
    // Handle network errors or other exceptions
    console.error("Error fetching task:", error);
    return null;
  }
}

// Function to fetch tasks for a participant
export async function getTasksForParticipant(
  authToken: string,
  participantId: string,
  page: number = 1,
  pageSize: number = 10
): Promise<PagedResult<TaskList> | null> {
  try {
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/tasks/participant/${participantId}?page=${page}&pageSize=${pageSize}`;

    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      // Handle non-OK responses, e.g., 404 for not found
      return null;
    }

    const res = await response.json();

    // Map the task data to your Task interface
    const tasks: TaskList[] = res.items.map((taskData: any) => ({
      id: taskData.id,
      title: taskData.title,
      assignees: taskData.assignees,
      description: taskData.description,
      orgId: taskData.organization,
      participant: taskData.participant,
      taskType: taskData.type,
      hasAttachments: taskData.hasAttachments,
      taskOrganization: taskData.taskOrg,
      subType: {
        id: taskData.subType.id,
        type: taskData.subType.name,
      },
      startDate: new Date(taskData.startDate),
      endDate: new Date(taskData.endDate),
      completionTime: taskData.completionTime
        ? new Date(taskData.completionTime)
        : undefined,
      progress: 100,
      status: taskData.taskStatus,
      priority: "LOW",
      assigneeRoleName: taskData.assigneeRoleName,
      fromTemplate: taskData.fromTemplate,
      // Add other necessary properties
    }));

    return {
      items: tasks,
      totalCount: res.totalCount,
    };
  } catch (error) {
    // Handle network errors or other exceptions
    console.error("Error fetching task:", error);
    return null;
  }
}

export async function publishTask(
  authToken: string,
  taskRequest: NewTask
): Promise<number | null> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/tasks`;

  // Converet NewTask type to TaskRequest type
  const requestBody: TaskRequest = {
    title: taskRequest.title,
    description: taskRequest.description,
    organizationId: 1,
    workflowGroup: taskRequest.workflowGroup,
    taskOrganization: Number(taskRequest.taskOrganization),
    taskType: Number(taskRequest.taskType),
    taskSubType: Number(taskRequest.taskSubType),
    participant: Number(taskRequest.participant),
    templateTaskId: undefined,
    requiredActions: undefined,
    requiredFiles: taskRequest.requiredFiles,
    assignee: taskRequest.assignee || "",
    coAssignee: taskRequest.coAssignee,
    endDate: taskRequest?.dueDate || Date.now().toString(),
  };

  console.log("RequestBody", requestBody);

  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      console.log(response.json());
      return null;
    }

    const taskId = await response.json();

    return taskId.result;
  } catch (error) {
    console.error("Error posting comment", error);
    return null;
  }
}

export async function publishTemplateInterimTask(
  authToken: string,
  taskRequest: NewTask
): Promise<number | null> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/tasks/fromTemplate`;

  console.log("templateTaskId", taskRequest.templateTaskId);
  console.log(taskRequest?.dueDate);

  // Converet NewTask type to TaskRequest type
  const requestBody: TaskRequest = {
    title: taskRequest.title,
    description: taskRequest.description,
    organizationId: 1,
    workflowGroup: taskRequest.workflowGroup,
    taskOrganization: Number(taskRequest.taskOrganization),
    taskType: Number(taskRequest.taskType),
    taskSubType: Number(taskRequest.taskSubType),
    participant: Number(taskRequest.participant),
    templateTaskId: taskRequest.templateTaskId,
    requiredActions: undefined,
    requiredFiles: taskRequest.requiredFiles,
    assignee: taskRequest.assignee || "",
    coAssignee: taskRequest.coAssignee,
    endDate: taskRequest?.dueDate || Date.now().toString(),
  };

  console.log("RequestBody", requestBody);

  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      console.log(response.json());
      return null;
    }

    const taskId = await response.json();

    return taskId.result;
  } catch (error) {
    console.error("Error posting comment", error);
    return null;
  }
}

export async function publishInterimTemplate(
  authToken: string,
  taskRequest: NewInterimTemplate
): Promise<number | null> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/tasks/template/interim`;

  // Converet NewTask type to TaskRequest type
  const requestBody: InterimTemplateRequest = {
    title: taskRequest.title,
    description: taskRequest.description,
    information: taskRequest.information,
    organizationId: 1,
    workflowGroup: taskRequest.workflowGroup,
    taskOrganization: Number(taskRequest.taskOrganization),
    taskType: Number(taskRequest.taskType),
    taskSubType: Number(taskRequest.taskSubType),
    participant: Number(taskRequest.participant),
    daysFromCreationDueDate: Number(taskRequest.daysFromCreationDueDate),
    templateTaskId: undefined,
    requiredActions: undefined,
    requiredFiles: taskRequest.requiredFiles,
    assignee: taskRequest.assignee || "",
    coAssignee: taskRequest.coAssignee || "",
    endDate: taskRequest?.dueDate || Date.now().toString(),
  };

  console.log("RequestBody", requestBody);

  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      console.log(response.json());
      return null;
    }

    const taskId = await response.json();

    return taskId;
  } catch (error) {
    console.error("Error posting interim template task", error);
    return null;
  }
}

export async function updateInterimTemplate(
  authToken: string,
  taskRequest: NewInterimTemplate
): Promise<number | null> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/tasks/template/interim`;

  // Converet NewTask type to TaskRequest type
  const requestBody: InterimTemplateRequest = {
    id: taskRequest?.id,
    title: taskRequest.title,
    description: taskRequest.description,
    information: taskRequest.information,
    organizationId: 1,
    workflowGroup: taskRequest.workflowGroup,
    taskOrganization: Number(taskRequest.taskOrganization),
    taskType: Number(taskRequest.taskType),
    taskSubType: Number(taskRequest.taskSubType),
    participant: Number(taskRequest.participant),
    daysFromCreationDueDate: Number(taskRequest.daysFromCreationDueDate),
    templateTaskId: undefined,
    requiredActions: undefined,
    requiredFiles: taskRequest.requiredFiles,
    assignee: taskRequest.assignee || "",
    coAssignee: taskRequest.coAssignee,
    endDate: taskRequest?.dueDate || Date.now().toString(),
  };

  console.log("RequestBody", requestBody);

  try {
    const response = await fetch(apiUrl, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      console.log(response.json());
      return null;
    }

    const taskId = await response.json();

    return taskId.result;
  } catch (error) {
    console.error("Error Updating InterimTemplate", error);
    return null;
  }
}

export async function getInterimTemplates(
  authToken: string
): Promise<InterimTemplateResponse[] | null> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/tasks/template/interim`;

  try {
    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      console.log(response.json());
      return null;
    }

    const templates = await response.json();
    console.log(templates, templates);
    // Converet NewTask type to TaskRequest type
    const templateInterims: InterimTemplateResponse[] = templates.map(
      (taskRequest: InterimTemplateResponse) => ({
        id: taskRequest.id,
        title: taskRequest.title,
        description: taskRequest.description,
        information: taskRequest.information,
        daysFromCreationDueDate: Number(taskRequest.daysFromCreationDueDate),
        // @TODO: Update this for post-BLOSSOM work
        organizationId: 1,
        taskOrganization: Number(taskRequest.taskOrganization),
        taskType: Number(taskRequest.taskType),
        taskSubType: Number(taskRequest.taskSubType),
        requiredFiles: taskRequest.requiredFiles,
      })
    );
    console.log(templateInterims);
    return templateInterims;
  } catch (error) {
    console.error("Error posting comment", error);
    return null;
  }
}

export async function deleteTaskById(
  authToken: string,
  taskId: string
): Promise<number | null> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/tasks/${taskId}`;

  try {
    const response = await fetch(apiUrl, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      console.log(response.json());
      return null;
    }

    return 1; // success
  } catch (error) {
    console.error("Error deleting task", error);
    return null;
  }
}
