import React, { useEffect, useState } from "react";
import Task from "../../../interfaces/task/Task";
import { formatDateToMMDDYYYY } from "../../../utilities/dateUtils";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../firebase/AuthProvider";
import { getTasks } from "../../../services/taskServices/TaskService";
import AssigneeWithTooltip from "../taskPage/AssigneeWithTooltip";
import TaskList from "../../../interfaces/task/TaskList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faCaretDown,
  faCaretRight,
} from "@fortawesome/free-solid-svg-icons";
import "./Tasks.css";
import { getAvatarsFromStorage } from "../../../services/fileServices/FileService";
import Avatar from "../../../interfaces/avatar/Avatar";
import TaskUserList from "./TaskUserList";
import SmallTasksPageTable from "./SmallTaskTable";

type TaskSectionProps = {
  overdueTasksUnfiltered: TaskList[] | undefined;
  tasksDueTodayUnfiltered: TaskList[] | undefined;
  tasksDueThisWeekUnfiltered: TaskList[] | undefined;
  tasksDueThisMonthUnfiltered: TaskList[] | undefined;
  nonTemplateInterimsUnfiltered: TaskList[] | undefined;
  allTasksUnfiltered: TaskList[] | undefined;
  userId?: string | null;
  toggleUserFilter: (id: string[]) => void;
};

const TaskSection: React.FC<TaskSectionProps> = ({
  tasksDueTodayUnfiltered,
  tasksDueThisWeekUnfiltered,
  tasksDueThisMonthUnfiltered,
  overdueTasksUnfiltered,
  nonTemplateInterimsUnfiltered,
  allTasksUnfiltered,
  userId,
  toggleUserFilter,
}) => {
  const maxTasksToShow = 10; // Maximum number of overdue tasks to display initially
  const { currentUser, currentRoles } = useAuth();

  const [activeList, setActiveList] = useState<string>("overdue");
  const [avatars, setAvatars] = useState<Avatar[] | null>();
  if (!userId) {
    userId = currentUser?.uid;
  }

  useEffect(() => {
    const storedAvatars = getAvatarsFromStorage();
    setAvatars(storedAvatars);
  }, []);

  var tasksDueToday = tasksDueTodayUnfiltered;
  var tasksDueThisWeek = tasksDueThisWeekUnfiltered;
  var tasksDueThisMonth = tasksDueThisMonthUnfiltered;
  var overdueTasks = overdueTasksUnfiltered;
  var nonTemplateInterims = nonTemplateInterimsUnfiltered;
  var allTasks = allTasksUnfiltered;
  allTasks = allTasksUnfiltered?.filter(
    (task) =>
      task.status.name !== "READY" && task.status.name !== "ISSUES FOUND"
  );
  if (
    currentRoles?.some((role) => role.name !== "SC") &&
    currentUser?.uid !== userId
  ) {
    tasksDueToday = tasksDueTodayUnfiltered?.filter(
      (task) =>
        task.status.name === "READY" || task.status.name === "ISSUES FOUND"
    );
    tasksDueThisWeek = tasksDueThisWeekUnfiltered?.filter(
      (task) =>
        task.status.name === "READY" || task.status.name === "ISSUES FOUND"
    );
    tasksDueThisMonth = tasksDueThisMonthUnfiltered?.filter(
      (task) =>
        task.status.name === "READY" || task.status.name === "ISSUES FOUND"
    );
    overdueTasks = overdueTasksUnfiltered?.filter(
      (task) =>
        task.status.name === "READY" || task.status.name === "ISSUES FOUND"
    );
    nonTemplateInterims = nonTemplateInterimsUnfiltered?.filter(
      (task) =>
        task.status.name === "READY" || task.status.name === "ISSUES FOUND"
    );

  } else if (currentRoles?.some((role) => role.name !== "SC")) {
  } else {
    tasksDueToday = tasksDueTodayUnfiltered?.filter(
      (task) =>
        task.status.name === "READY" || task.status.name === "ISSUES FOUND"
    );
    tasksDueThisWeek = tasksDueThisWeekUnfiltered?.filter(
      (task) =>
        task.status.name === "READY" || task.status.name === "ISSUES FOUND"
    );
    tasksDueThisMonth = tasksDueThisMonthUnfiltered?.filter(
      (task) =>
        task.status.name === "READY" || task.status.name === "ISSUES FOUND"
    );
    overdueTasks = overdueTasksUnfiltered?.filter(
      (task) =>
        task.status.name === "READY" || task.status.name === "ISSUES FOUND"
    );
    nonTemplateInterims = nonTemplateInterimsUnfiltered?.filter(
      (task) =>
        task.status.name === "READY" || task.status.name === "ISSUES FOUND"
    );
  }

  return (
    <div className="flex max-w-3/5 2xl: sm:w-full mx-auto dark:text-text-dark relative -top-44 mt-10 flex-grow h-auto min-h-[446px] flex-wrap overflow-wrap  justify-center gap-5">
      <div className="h-[300px] w-full bg-navBackground dark:bg-navBackground-dark -z-10 rounded-b-3xl"></div>
      <div className="bg-[#F7F9FF] w-11/12 mx-auto rounded-3xl relative -top-64 -mt-10 max-w-[1800px] min-h-[500px] border shadow-lg overflow-y-hidden">
        <h1 className="text-text inter-font text-2xl p-5">Dashboard</h1>
        <div className="dashboard-container flex h-full overflow-x-auto">
          <TaskUserList avatars={avatars} toggleUserFilter={toggleUserFilter} />
          <div className="task-collapsibles w-4/6">
            <div
              onClick={() => setActiveList("overdue")}
              className={`px-5 font-bold inter-font rounded-lg mx-2 mb-2 task-list overdue border text-lg ${
                activeList === "overdue" ? "bg-white" : "bg-gray-200"
              }`}
            >
              <div className="flex justify-between border-b">
                <div className="flex align-middle items-center gap-2">
                  Overdue{" "}
                  <div className="text-gray-500 text-base">
                    ({overdueTasks?.length ? overdueTasks.length : "0"})
                  </div>
                </div>{" "}
                <div>
                  <FontAwesomeIcon
                    icon={activeList === "overdue" ? faCaretDown : faCaretRight}
                  />
                </div>
              </div>
              <div className="text-sm">
                {activeList === "overdue" && overdueTasks && (
                  <div className=" bg-white -ps-10">
                    <SmallTasksPageTable
                      tasks={overdueTasks}
                      displayTaskCount={false}
                      avatars={avatars || []}
                    />
                  </div>
                )}
                {activeList === "overdue" && overdueTasks?.length === 0 && (
                  <div>There are currently no tasks to display...</div>
                )}
              </div>
            </div>
            <div
              onClick={() => setActiveList("due-today")}
              className={`px-5 font-bold inter-font rounded-lg mx-2 mb-2 task-list due-today border text-lg ${
                activeList === "due-today" ? "bg-white" : "bg-gray-200"
              }`}
            >
              <div className="flex justify-between border-b">
                <div className="flex align-middle items-center gap-2">
                  Due Today{" "}
                  <div className="text-gray-500 text-base">
                    ({tasksDueToday?.length ? tasksDueToday.length : "0"})
                  </div>
                </div>{" "}
                <div>
                  <FontAwesomeIcon
                    icon={
                      activeList === "due-today" ? faCaretDown : faCaretRight
                    }
                  />
                </div>
              </div>
              <div className="text-sm">
                {activeList === "due-today" &&
                  tasksDueToday &&
                  tasksDueToday?.length > 0 && (
                    <div className=" bg-white -ps-10">
                      <SmallTasksPageTable
                        tasks={tasksDueToday}
                        displayTaskCount={false}
                        avatars={avatars || []}
                      />
                    </div>
                  )}
                {activeList === "due-today" && tasksDueToday?.length === 0 && (
                  <div className="bg-white mt-5">
                    There are currently no tasks to display...
                  </div>
                )}
              </div>
            </div>
            <div
              onClick={() => setActiveList("due-this-week")}
              className={`px-5 font-bold inter-font rounded-lg mx-2 mb-2 task-list due-this-week border text-lg ${
                activeList === "due-this-week" ? "bg-white" : "bg-gray-200"
              }`}
            >
              <div className="flex justify-between border-b">
                <div className="flex align-middle items-center gap-2">
                  Due This Week{" "}
                  <div className="text-gray-500 text-base">
                    ({tasksDueThisWeek?.length ? tasksDueThisWeek.length : "0"})
                  </div>
                </div>{" "}
                <div>
                  <FontAwesomeIcon
                    icon={
                      activeList === "due-this-week"
                        ? faCaretDown
                        : faCaretRight
                    }
                  />
                </div>
              </div>
              <div className="text-sm">
                {activeList === "due-this-week" &&
                  tasksDueThisWeek &&
                  tasksDueThisWeek.length > 0 && (
                    <div className=" bg-white -ps-10">
                      <SmallTasksPageTable
                        tasks={tasksDueThisWeek}
                        displayTaskCount={false}
                        avatars={avatars || []}
                      />
                    </div>
                  )}
                {activeList === "due-this-week" &&
                  tasksDueThisWeek?.length === 0 && (
                    <div className="mt-5">
                      There are currently no tasks to display...
                    </div>
                  )}
              </div>
            </div>
            <div
              onClick={() => setActiveList("due-this-month")}
              className={`px-5 font-bold inter-font rounded-lg mx-2 mb-2 task-list due-this-month border text-lg ${
                activeList === "due-this-month" ? "bg-white" : "bg-gray-200"
              }`}
            >
              <div className="flex justify-between border-b">
                <div className="flex align-middle items-center gap-2">
                  Due This Month{" "}
                  <div className="text-gray-500 text-base">
                    (
                    {tasksDueThisMonth?.length ? tasksDueThisMonth.length : "0"}
                    )
                  </div>
                </div>{" "}
                <div>
                  <FontAwesomeIcon
                    icon={
                      activeList === "due-this-month"
                        ? faCaretDown
                        : faCaretRight
                    }
                  />
                </div>
              </div>
              <div className="text-sm">
                {activeList === "due-this-month" && tasksDueThisMonth && (
                  <div className=" bg-white -ps-10">
                    <SmallTasksPageTable
                      tasks={tasksDueThisMonth}
                      displayTaskCount={false}
                      avatars={avatars || []}
                    />
                  </div>
                )}
                {activeList === "due-this-month" &&
                  tasksDueThisMonth?.length === 0 && (
                    <div className="mt-5">
                      There are currently no tasks to display...
                    </div>
                  )}
              </div>
            </div>
            <div
              onClick={() => setActiveList("interim")}
              className={`px-5 font-bold inter-font rounded-lg mx-2 mb-2 task-list interim border text-lg ${
                activeList === "interim" ? "bg-white" : "bg-gray-200"
              }`}
            >
              <div className="flex justify-between border-b">
                <div className="flex align-middle items-center gap-2">
                  Interim{" "}
                  <div className="text-gray-500 text-base">
                    (
                    {nonTemplateInterims?.length
                      ? nonTemplateInterims.length
                      : "0"}
                    )
                  </div>
                </div>{" "}
                <div>
                  <FontAwesomeIcon
                    icon={activeList === "interim" ? faCaretDown : faCaretRight}
                  />
                </div>
              </div>
              <div className="text-sm">
                {activeList === "interim" && nonTemplateInterims && (
                  <div className=" bg-white -ps-10">
                    <SmallTasksPageTable
                      tasks={nonTemplateInterims}
                      displayTaskCount={false}
                      avatars={avatars || []}
                    />
                  </div>
                )}
                {activeList === "interim" &&
                  nonTemplateInterims?.length === 0 && (
                    <div className="mt-5">
                      There are currently no tasks to display...
                    </div>
                  )}
              </div>
            </div>
          </div>
          <div className="aging-report-section bg-white rounded-lg mx-5 mb-10 border w-2/6">
            <div className="review-section p-2">
              <h2 className="inter-font text-text text-lg text-center">
                Awaiting Review
              </h2>
              <div className="review-content-section">
                {/* Let's talk about the conditions here.
                  If this is an SC, we are going to want to get all assignments that aren't assigned to them, right? 
                */}
                <ReviewTable tasks={allTasks} avatars={avatars} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

type ReviewTableProps = {
  tasks: TaskList[] | undefined;
  avatars: Avatar[] | undefined | null;
};

const ReviewTable: React.FC<ReviewTableProps> = ({ tasks, avatars }) => {
  const findAvatarById = (userId: string): string | null => {
    const avatarObj = avatars?.find((avatar) => avatar.id === userId);
    return avatarObj ? `data:image/png;base64,${avatarObj.avatar}` : null;
  };

  const navigate = useNavigate();

  return (
    <div className="review-table-container text-[12px]">
      <div className="headers">
        <div className="col-header">Type</div>
        <div className="col-header">Participant</div>
        <div className="col-header">Waiver</div>
        <div className="col-header">Assignee</div>
        <div className="col-header">Due Date</div>
      </div>
      {tasks?.map((task, index) => (
        <div key={index} className="table-data" onClick={() => navigate(`/task/BLOSSOM-${task.id}`)}>
          <div className="col-data">
            {task.subType.type
              ? task.subType.type
                  .toLowerCase()
                  .split(" ")
                  .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                  .join(" ")
              : "N/A"}
          </div>
          <div className="col-data">
            {task.participant
              ? task.participant?.firstName + " " + task.participant?.lastName
              : "N/A"}
          </div>
          <div className="col-data">
            {task.participant?.waiver?.waiverName
              ? task.participant?.waiver?.waiverName
              : "N/A"}
          </div>
          <div className="col-data flex">
            {task.assignees
              .filter((a) => !a.isReporter) // Exclude reporters
              .map((user, index) => {
                const avatarUrl = findAvatarById(user.user.id);
                return (
                  <div
                    className="w-3 h-3 font-bold flex items-center justify-center rounded-full bg-gray-300 dark:text-text"
                    key={index}
                  >
                    {avatarUrl ? (
                      <img
                        src={avatarUrl}
                        alt={`${user.user.firstName} ${user.user.lastName}`}
                        className="w-full h-full rounded-full object-cover"
                      />
                    ) : (
                      <div className="text-[8px]">
                        {user.user.firstName[0] + user.user.lastName[0]}
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
          <div className="col-data">{formatDateToMMDDYYYY(task.endDate)}</div>
        </div>
      ))}
    </div>
  );
};

type TaskTableProps = {
  overdueTasks: TaskList[] | undefined;
  tasksDueToday: TaskList[] | undefined;
  tasksDueThisWeek: TaskList[] | undefined;
  tasksDueThisMonth: TaskList[] | undefined;
  nonTemplateInterims: TaskList[] | undefined;
  allTasks: TaskList[] | undefined;
  userId?: string | null;
  toggleUserFilter: (id: string[]) => void;
};

const TaskTable: React.FC<TaskTableProps> = ({
  overdueTasks,
  tasksDueToday,
  tasksDueThisWeek,
  tasksDueThisMonth,
  nonTemplateInterims,
  allTasks,
  userId,
  toggleUserFilter,
}) => {
  return (
    <div className="flex flex-col w-full flex-wrap flex-grow h-auto">
      <TaskSection
        overdueTasksUnfiltered={overdueTasks}
        tasksDueThisMonthUnfiltered={tasksDueThisMonth}
        tasksDueThisWeekUnfiltered={tasksDueThisWeek}
        tasksDueTodayUnfiltered={tasksDueToday}
        nonTemplateInterimsUnfiltered={nonTemplateInterims}
        allTasksUnfiltered={allTasks}
        userId={userId}
        toggleUserFilter={toggleUserFilter}
      />
    </div>
  );
};

interface TaskProps {
  userId?: string;
}

const Tasks: React.FC<TaskProps> = ({ userId = null }) => {
  const [tasks, setTasks] = useState<TaskList[] | undefined>([]);
  const [allTasks, setAllTasks] = useState<TaskList[] | null>([]);
  const [userFilter, setUserFilter] = useState<string[]>([]);

  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser !== null) {
      const fetchTasksForCurrentUser = async () => {
        const authToken: string = await currentUser.getIdToken();
        let fetchedTasks: TaskList[] | undefined = [];
        if (userId) {
          fetchedTasks = await getTasks(authToken, userId).then((task) =>
            task?.filter((t) => t.status.name !== "COMPLETED")
          );
        } else {
          const userId = currentUser.uid;
          fetchedTasks = await getTasks(authToken, userId).then((task) =>
            task?.filter((t) => t.status.name !== "COMPLETED")
          );
        }
        const sortedTasks = fetchedTasks?.sort(
          (a, b) =>
            new Date(a.endDate).getTime() - new Date(b.endDate).getTime()
        );
        if (sortedTasks) {
          setAllTasks(sortedTasks);
          if (tasks?.length === 0 && userFilter.length === 0)
            setTasks(sortedTasks);
        }
      };

      fetchTasksForCurrentUser();
    }

    if (allTasks && userFilter.length > 0) {
      const temp = allTasks.filter((task) => {
        return task.assignees.some((assignee) =>
          userFilter.includes(assignee.user.id)
        );
      });
      setTasks(temp);
    } else if (allTasks) {
      setTasks(allTasks);
    }
  }, [currentUser, userFilter]);

  const toggleUserFilter = (ids: string[]) => {
    setUserFilter(ids);
  };

  const today = new Date();
  const endOfToday = new Date(today);
  endOfToday.setHours(23, 59, 59, 999);

  const endOfThisWeek = new Date(today);
  endOfThisWeek.setDate(endOfThisWeek.getDate() + (7 - endOfThisWeek.getDay()));

  const endOfNextMonth = new Date(today);
  endOfNextMonth.setMonth(endOfNextMonth.getMonth() + 1);
  endOfNextMonth.setHours(23, 59, 59, 999);

  const tasksDueToday: TaskList[] | null | undefined = tasks?.filter(
    (task) =>
      task.endDate >= today && task.endDate <= endOfToday && task.fromTemplate
  );
  const tasksDueThisWeek: TaskList[] | null | undefined = tasks?.filter(
    (task) =>
      task.endDate > endOfToday &&
      task.endDate <= endOfThisWeek &&
      task.fromTemplate
  );
  const tasksDueThisMonth: TaskList[] | null | undefined = tasks?.filter(
    (task) =>
      task.endDate > endOfThisWeek &&
      task.endDate <= endOfNextMonth &&
      task.fromTemplate
  );
  const overdueTasks: TaskList[] | null | undefined = tasks?.filter(
    (task) => task.endDate < today && task.fromTemplate
  );

  const nonTemplateInterims: TaskList[] | null | undefined = tasks?.filter(
    (task) => !task.fromTemplate
  );

  return (
    <div className="flex flex-col flex-wrap flex-grow h-auto">
      <TaskTable
        overdueTasks={overdueTasks}
        tasksDueThisMonth={tasksDueThisMonth}
        tasksDueThisWeek={tasksDueThisWeek}
        tasksDueToday={tasksDueToday}
        nonTemplateInterims={nonTemplateInterims}
        allTasks={tasks}
        userId={userId}
        toggleUserFilter={toggleUserFilter}
      />
    </div>
  );
};

export default Tasks;
